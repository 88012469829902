import {
  title,
  description,
  mrAuto,
  mlAuto
} from "assets/jss/styles.js";

const servicesStyle = {
  title: {
    ...title,
    fontSize: "2.5rem"
  },
  mrAuto,
  mlAuto,
  services: {
    paddingTop: "10px",
    paddingBottom: "80px"
  },
  textCenter: {
    textAlign: "center"
  },
  description: {
    ...description,
    fontSize: "1rem"
  },
  list: {
    marginTop: "15px",
    "& li": {
      lineHeight: "2.5rem"
    }
  }
};

export default servicesStyle;
