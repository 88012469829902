import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Gesture from "@material-ui/icons/Gesture";
import Build from "@material-ui/icons/Build";
import BorderColorIcon from '@material-ui/icons/BorderColor';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import servicesStyle from "assets/jss/styles/pages/landing/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServicesLanding() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto
          )}
        >
          <h2 className={classes.title}>
            Suburled - Empresa de electricidad en Barcelona y provincia
          </h2>
          <p className={classes.description}>
            Somos especialistas en <b>instalaciones eléctricas de baja tensión</b>, tanto domésticas como industriales. 
          </p>
          <p className={classes.description}>
            Desde nuestros comienzos, hemos realizado obras de distintas naturaleza, desde infinidad de <b>reformas eléctricas de viviendas y locales comerciales</b> hasta obras de envergadura, tales como centros comerciales, estaciones de tren o multitud de naves industriales, ocupándonos de todo el proceso desde en centro de transformación y obra civil hasta la instalación de interior.
          </p>
          <p className={classes.description}>
            <b>Abarcamos todo el proceso de la obra</b>, desde la elaboración del <b>proyecto</b> hasta la <b>ejecución de la obra</b> y la <b>legalización</b>. Nuestro ámbito de actuación abarca:
            <ul className={classes.list}>
              <li><b>Instalaciones eléctricas domésticas.</b></li>
              <li><b>Instalaciones eléctricas de locales comerciales, hoteles y restaurantes.</b></li>
              <li><b>Instalaciones eléctricas industriales.</b></li>
              <li><b>Instalaciones eléctricas en comunidades de vecinos.</b></li>
              <li><b>Mantenimiento y averías.</b></li>
              <li><b>Urgencias.</b></li>
              <li><b>Cargadores de coches eléctricos.</b></li>
            </ul>
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
